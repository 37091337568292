<template>
  <div class="taskCenter">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='taskCenter'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="任务中心" name="first"></el-tab-pane>
          </el-tabs>
          <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px">
            <el-form-item label="任务名称">
              <el-input v-model="formInline.nameSearch" placeholder="任务名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-select v-model="formInline.taskType" placeholder="选择类型" clearable>
                <el-option label="导入" value="IMPORT"></el-option>
                <el-option label="导出" value="EXPORT"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态">
              <el-select v-model="formInline.taskStatus" placeholder="选择状态" clearable>
                <el-option label="处理中" value="PROCESSING"></el-option>
                <el-option label="处理完成" value="COMPLETE"></el-option>
                <el-option label="处理超时" value="TIMEOUT"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="table_sList">
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center">
            <el-table-column
              prop="number"
              label="任务ID"
              align="center"
              width="180">
            </el-table-column>
            <el-table-column
              prop="taskName"
              label="任务名称"
              align="center"
              width="140">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.taskName" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.taskName}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="fileName"
              label="文件名称"
              align="center"
              width="200">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.fileName" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.fileName}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="taskType"
              label="任务类型"
              align="center"
              width="120">
            </el-table-column>
            <el-table-column
              prop="taskStatus"
              label="任务状态"
              align="center"
              width="120">
            </el-table-column>
            <el-table-column
              prop="taskResult"
              label="处理结果"
              align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.taskResult" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.taskResult}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="createBy"
              label="创建人"
              align="center">
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center">
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.createTime, 'datetime')}}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="详情"
              width="140"
              align="center">
              <template slot-scope="scope">
                <el-button size="small" v-show="scope.row.downloadUrl" type="text" @click="watchResult(scope.row)">查看结果</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
  </div>
</template>

<script>
import publicChildren from './publicChildren'
import Pagination from './Pagination'
import way from '../api/encapsulation'
export default {
  name: 'taskCenter',
  components: {
    publicChildren,
    Pagination,
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        nameSearch: '',
        page: 1,
        pageSize: 10,
        taskStatus: '',
        taskType: ''
      },
      tableData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if(newVal.name == 'taskCenter') {
          this.getTaskTableDataEvent();
        }
      }
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    // 获取任务中心表格数据
    getTaskTableDataEvent() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getTaskTableData(obj, res => {
        this.loading = false;
        if(res.code == 0){
          let {records, current, size, total} = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    // 重置
    resetEvent() {
      this.formInline.nameSearch = '';
      this.formInline.taskType = '';
      this.formInline.taskStatus = '';
    },
    onSubmit() {
      this.formInline.page = 1;
      this.formInline.pageSize = 10;
      this.getTaskTableDataEvent();
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getTaskTableDataEvent();
    },
    // 查看结果
    watchResult(row) {
      window.location.href = row.downloadUrl;
      // let obj = {
      //   url: row.downloadUrl,
      //   fileName: '优课学堂.xls'
      // }
      // way.downloadFileUrl(obj)
      // this.$request.download(row.downloadUrl).then(res => [
      //   console.log(res)
      // ])
    }
  },
}
</script>

<style lang="less">
  .taskCenter {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      // ::-webkit-scrollbar {
      //   width: 8px;
      //   height: 8px;
      //   background-color: #fff;
      // }
      // ::-webkit-scrollbar-thumb {
      //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      //     background-color: rgba(0, 0, 0, .1);
      //     border-radius: 3px;
      //     border-top-right-radius: 4px;
      //     border-bottom-right-radius: 4px;
      // }
      // .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
      //     border-right: none;
      // }
      .el-pagination {
        text-align: right;
      }
    }
    .ellipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .pubToop {
    max-width: 600px;
  }
</style>